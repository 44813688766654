@import '/styles/mixins.scss';
@import '/styles/variables.scss';

.container{
  @include size(100vw , 100vh);
  @include flex(column, center, center);
  background-image: url(../public/images/background.jpg);
  background-position: center;
  background-size: cover;
  font-family: $rubik;

  img{
    width: 65%;
    &.five{
    width: 50%;
    margin-bottom: -50px;
    }
  }
  .errorMessage{
    @include flex(column, center, center);
    text-align: center;
    color: #fff;
    margin: 2rem 0;
    p{
      margin: 1rem 0;
    }
    button{
      padding: 0.5rem 2rem;
      span{
        margin: 0;
      }
    }
  }
}